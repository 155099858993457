import requestService from "../requestService";
import store from '@/store';

export default {
	async createStation(form) {
		const response = await requestService.post(`/cp_dash/station`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async updateStation(id, form) {
		const response = await requestService.post(`/cp_dash/station/${id}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getStations(page) {
		const response = await requestService.get(`/cp_dash/station?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async searchStations(search) {
		const response = await requestService.get(`/cp_dash/station/search/${search}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getAllStation() {
		const response = await requestService.get(`/cp_dash/station/all`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getStation(id) {
		const response = await requestService.get(`/cp_dash/station/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async deleteStation(id) {
		const response = await requestService.delete(`/cp_dash/station/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
}