<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 30px">
      <v-col cols="4" class="px-0 py-0">
        <searchField
          label="Пошук зупинки"
          placeholder="Введіть тут.."
          @search="searchStation"
        ></searchField>
      </v-col>
      <v-row no-gutters justify="end">
        <submit-button
          style="height: 48px !important; width: 220px !important"
          @click="showCreateNewStationModal = true"
        >
          <v-icon style="margin-right: 8px">mdi-plus</v-icon> Додати
          зупинку</submit-button
        >
      </v-row>
    </v-row>
    <change-lang-form @setLang="setLang" />
    <v-data-table
      :headers="headers"
      :items="stations"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      no-data-text="Поки що ви не додали жодної зупинки"
      background-color="red"
      :loading="showLoader"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            !head.sortable && head.sortable !== false
              ? (sortBy = head.value)((sortDesc = !sortDesc))
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: #1b1b1b;
                cursor: pointer;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <span class="tableItem">{{ item.id }}</span>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div style="width: 200px">
          <span class="tableItem">{{
            item.translations.find((station) => station.lang == activeLang)
              ?.name
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.city`]="{ item }">
        <div style="width: 150px">
          <span class="tableItem">{{
            item?.city?.translations.find((city) => city.lang == activeLang)
              ?.name
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        <div style="width: 250px">
          <span class="tableItem">{{
            item.translations.find((country) => country.lang == activeLang)
              ?.address
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters align="center" justify="end">
          <div class="iconBackground">
            <div
              class="editIcon"
              v-if="!item.is_new && !item.is_edit"
              @click="showEditStationField(item)"
            />
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.quantity_in_trip`]="{ item }">
        <span class="tableItem">{{ item.quantity_in_trip }}</span>
      </template>
    </v-data-table>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
    <add-new-station-modal
      v-if="showCreateNewStationModal"
      :cities="cities"
      :isEdit="isEdit"
      :stationForEdit="stationForEdit"
      :visible="showCreateNewStationModal"
      @successCreate="
        (showLoader = true),
          (isEdit = false),
          (showCreateNewStationModal = false),
          getStations()
      "
      @close="showCreateNewStationModal = false, isEdit = false"
    />
  </v-col>
</template>

<script>
import cityService from "../../../../requests/Admin/cityService";
import stationService from "../../../../requests/Admin/stationService";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import searchField from "../../../UI/Fields/searchField.vue";
import ChangeLangForm from "../../../UI/Forms/changeLangForm.vue";
import Pagination from "../../../UI/pagination.vue";
import AddNewStationModal from "./addNewStationModal.vue";
export default {
  components: {
    searchField,
    SubmitButton,
    ChangeLangForm,
    AddNewStationModal,
    Pagination,
  },
  data: () => ({
    sortBy: "",
    sortDesc: "",
    showConfirmModal: false,
    showCreateNewStationModal: false,
    headers: [
      {
        text: "№",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Станція", value: "name", align: "start" },
      { text: "Місто", value: "city", align: "start" },
      {
        text: "Адреса",
        value: "address",
        align: "start",
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    cities: [],
    stations: [],
    stationForDeleteId: "",
    stationForEdit: {},
    searchValue: "",
    stationError: [],
    showLoader: true,
    isEdit: false,
    page: 1,
    quantityPage: 0,
    activeLang: "ua",
  }),
  mounted() {
    this.getStations();
    this.getCities();
  },
  methods: {
    setLang(lang) {
      this.activeLang = lang;
    },
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getStations();
    },
    async getCities() {
      await cityService.getCities().then((res) => {
        if (res.status == "Success") {
          this.cities = res.data;
        }
      });
    },
    async getStations() {
      await stationService.getStations(this.page).then((res) => {
        if (res.status == "Success") {
          this.stations = res.data;
          this.quantityPage = res.pagination.meta.last_page;
          this.showLoader = false;
        }
      });
    },
    async searchStation(search) {
      this.showLoader = true;
      if (search !== "") {
        await stationService.searchStations(search).then((res) => {
          if (res.status == "Success") {
            this.stations = res.data;
            this.showLoader = false;
          }
        });
      } else {
        this.getStations();
      }
    },
    showEditStationField(station) {
      this.stationForEdit = station;
      this.isEdit = true;
      this.showCreateNewStationModal = true;
    },
  },
};
</script>

<style>
</style>