<template>
  <v-dialog v-model="visibility" width="720px">
    <error-snackbar v-if="showErrorSnackbar" :snackbarText="snackbarText" />
    <div style="border-radius: 10px; background: #fff; padding: 16px">
      <v-row no-gutters align="center">
        <h2
          style="
            color: #1b1b1b;
            font-family: 'MacPaw Fixel';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          {{ isEdit ? `Зупинка` : "Нова зупинка" }}
          <span v-if="isEdit" style="color: #144fa9">{{ station.name }}</span>
        </h2>
        <!-- <v-row no-gutters align="center" justify="end" v-if="isEdit">
          <v-btn class="deleteStationBtn">Видалити</v-btn>
        </v-row> -->
      </v-row>

      <div class="sectionLine"></div>
      <v-row no-gutters align="center">
        <div
          class="statusBox"
          :class="activeLang == 'ua' ? 'activeStatusBox' : ''"
          @click="activeLang = 'ua'"
        >
          Українська
        </div>
        <div
          class="statusBox"
          :class="activeLang == 'en' ? 'activeStatusBox' : ''"
          @click="activeLang = 'en'"
        >
          English
        </div>
        <div
          class="statusBox"
          :class="activeLang == 'pl' ? 'activeStatusBox' : ''"
          @click="activeLang = 'pl'"
        >
          Polish
        </div>
      </v-row>
      <v-text-field
        dense
        outlined
        style="
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          margin-top: 20px;
        "
        height="48px"
        label="Назва зупинки"
        placeholder="Введіть назву зупинки.."
        v-model="nameModel"
        :error-messages="nameError"
      />
      <v-autocomplete
        dense
        outlined
        style="
          border-radius: 10px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        "
        height="48px"
        label="Місто"
        placeholder="Введіть назву міста.."
        v-model="station.city"
        :items="Object.values(cityList)"
        :item-text="'alternative_name'"
        :item-value="'id'"
        :error-messages="cityError"
      />
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            style="
              border-radius: 10px;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            "
            height="48px"
            label="Адреса"
            placeholder="Введіть Адреса.."
            v-model="addressModel"
            :error-messages="addressError"
            v-bind="attrs"
            v-on="on"
            hide-details
          />
        </template>
        <div
          style="
            border: 1px solid;
            border-color: #c0c0c0;
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            width: 100%;
            margin-bottom: 5px;
          "
        >
          <vue-google-autocomplete
            id="address"
            v-model="address"
            @placechanged="(address) => onPlaceChanged(address)"
            style="height: 48px; width: 100%; padding: 0px 10px"
          />
        </div>
      </v-menu>

      <v-btn
        width="100%"
        height="48px"
        style="
          margin-top: 40px;
          color: #fafafa !important;
          font-family: 'MacPaw Fixel';
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          border-radius: 10px;
          text-transform: none;
        "
        color="#144FA9"
        @click="isEdit ? updateStation() : createStation()"
        >{{ isEdit ? "Оновити" : "Додати" }}
      </v-btn>
      <v-row no-gutters justify="center" style="margin-top: 10px">
        <cancel-btn text="Скасувати" @click="$emit('close')" />
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import modalMixin from "../../../../mixins/modalMixin";
import stationService from "../../../../requests/Admin/stationService";
import requestFormData from "../../../../requests/requestFormData";
import CancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import ErrorSnackbar from "../../../UI/Snackbars/errorSnackbar.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  mixins: [modalMixin, validationMixin],
  components: {
    CancelBtn,
    ErrorSnackbar,
    VueGoogleAutocomplete,
  },
  data: () => ({
    station: {
      name: "",
      name_en: "",
      name_pl: "",
      address: "",
      address_en: "",
      address_pl: "",
      station: "",
    },
    address: "",
    showErrorSnackbar: false,
    snackbarText: "",
    searchValue: "",
    searchTimeout: null,
    cityList: [],
    activeLang: "ua",
  }),
  props: {
    isEdit: {
      require: false,
    },
    stationForEdit: {
      require: true,
    },
    cities: {
      require: false,
    },
  },
  validations: {
    station: {
      name: {
        required,
      },
      address: {
        required,
      },
      city: {
        required,
      },
    },
  },
  mounted() {
    if (this.isEdit) {
      this.setStation();
    }
    this.cityList = [...this.cities];
  },
  methods: {
    async createStation() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          city_uuid: this.station.city,
          lat: this.station.lat,
          lng: this.station.lng,
          translations: [
            {
              lang: "ua",
              name: this.station.name,
              address: this.station.address,
            },
            {
              lang: "en",
              name: this.station.name_en
                ? this.station.name_en
                : this.station.name,
              address: this.station.address_en
                ? this.station.address_en
                : this.station.address,
            },
            {
              lang: "pl",
              name: this.station.name_pl
                ? this.station.name_pl
                : this.station.name,
              address: this.station.address_pl
                ? this.station.address_pl
                : this.station.address,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await stationService.createStation(form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    async updateStation() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          city_uuid: this.station.city,
          lat: this.station.lat,
          lng: this.station.lng,
          translations: [
            {
              id: this.station.translation_id,
              lang: "ua",
              name: this.station.name,
              address: this.station.address,
            },
            {
              id: this.station.translation_id_en,
              lang: "en",
              name: this.station.name_en
                ? this.station.name_en
                : this.station.name,
              address: this.station.address_en
                ? this.station.address_en
                : this.station.address,
            },
            {
              id: this.station.translation_id_pl,
              lang: "pl",
              name: this.station.name_pl
                ? this.station.name_pl
                : this.station.name,
              address: this.station.address_pl
                ? this.station.address_pl
                : this.station.address,
            },
          ],
        };

        let form = requestFormData.jsonToFormData(data);
        await stationService
          .updateStation(this.station.id, form)
          .then((res) => {
            if (res.status == "Success") {
              this.$emit("successCreate");
            }
          });
      }
    },
    onPlaceChanged(place) {
      if (this.activeLang == "ua") {
        this.station.address = `${place.route}, ${
          place.street_number ? place.street_number : ""
        }`;
      } else if (this.activeLang == "en") {
        this.station.address_en = `${place.route}, ${
          place.street_number ? place.street_number : ""
        }`;
      } else if (this.activeLang == "pl") {
        this.station.address_pl = `${place.route}, ${
          place.street_number ? place.street_number : ""
        }`;
      }

      this.station.lat = place.latitude;
      this.station.lng = place.longitude;
    },
    async searchCountry() {
      await stationService.searchCountry(this.searchValue).then((res) => {
        if (res.status == "Success") {
          this.cityList = res.data;
        }
      });
    },
    setStation() {
      this.$set(this.station, "id", this.stationForEdit.id);
      this.$set(this.station, "lat", this.stationForEdit.lat);
      this.$set(this.station, "lng", this.stationForEdit.lng);
      this.$set(
        this.station,
        "alternative_name",
        this.stationForEdit.alternative_name
      );
      this.$set(this.station, "city", this.stationForEdit.city_uuid);
      this.$set(
        this.station,
        "translation_id",
        this.stationForEdit.translations.find((station) => station.lang == "ua")
          ?.id
      );
      this.$set(
        this.station,
        "translation_id_en",
        this.stationForEdit.translations.find((station) => station.lang == "en")
          ?.id
      );
      this.$set(
        this.station,
        "translation_id_pl",
        this.stationForEdit.translations.find((station) => station.lang == "pl")
          ?.id
      );
      this.$set(
        this.station,
        "name",
        this.stationForEdit.translations.find((station) => station.lang == "ua")
          ?.name
      );
      this.$set(
        this.station,
        "name_en",
        this.stationForEdit.translations.find((station) => station.lang == "en")
          ?.name
      );
      this.$set(
        this.station,
        "name_pl",
        this.stationForEdit.translations.find((station) => station.lang == "pl")
          ?.name
      );
      this.$set(
        this.station,
        "address",
        this.stationForEdit.translations.find((station) => station.lang == "ua")
          ?.address
      );
      this.$set(
        this.station,
        "address_en",
        this.stationForEdit.translations.find((station) => station.lang == "en")
          ?.address
      );
      this.$set(
        this.station,
        "address_pl",
        this.stationForEdit.translations.find((station) => station.lang == "pl")
          ?.address
      );
    },
  },
  computed: {
    nameError() {
      const errors = [];
      let field = this.$v.station.name;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    addressError() {
      const errors = [];
      let field = this.$v.station.address;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    cityError() {
      const errors = [];
      let field = this.$v.station.city;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    nameModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.station.name;
          }
          case "en": {
            return this.station.name_en;
          }
          case "pl": {
            return this.station.name_pl;
          }
        }
        return this.station.name;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.station.name = val;
            }
            break;
          case "en":
            {
              this.station.name_en = val;
            }
            break;
          case "pl":
            {
              this.station.name_pl = val;
            }
            break;
        }
      },
    },
    addressModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.station.address;
          }
          case "en": {
            return this.station.address_en;
          }
          case "pl": {
            return this.station.address_pl;
          }
        }
        return this.station.address;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.station.address = val;
            }
            break;
          case "en":
            {
              this.station.address_en = val;
            }
            break;
          case "pl":
            {
              this.station.address_pl = val;
            }
            break;
        }
      },
    },
  },
  watch: {
    searchValue: {
      deep: true,
      handler() {
        if (
          this.searchValue !== "" &&
          this.searchValue !== undefined &&
          this.searchValue !== null
        ) {
          clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.searchCountry();
          }, 300);
        }
      },
    },
  },
};
</script>

<style scoped>
.deleteStationBtn {
  width: 160px !important;
  height: 46px !important;
  display: grid;
  place-items: center;
  border-radius: 10px !important;
  background: rgba(223, 58, 58, 0.1) !important;
  color: #df3a3a !important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}
</style>